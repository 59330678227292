<template>
  <div class="cart-empty">
    <h1 v-html="$t('terminal.cart.cart.empty')"></h1>
    <div class="description" v-html="$t('terminal.cart.cart.emptyDescription')"></div>
  </div>
</template>

<script>
export default {
  name: 'cart-empty',
};
</script>
